import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import Accordion from "@/components/accordion";

import ResponsiveImage from "../ResponsiveImage";
import styles from "./ComingSoonPresaleClub.module.scss";
import "./animations.module.scss";

const ComingSoonPresaleClub = ({ club }) => {
  const comingSoonRef = useRef();
  const photoRef = useRef();
  const contentRef = useRef();

  const [selectedAccordion, setSelectedAccordion] = useState("0");

  /**
   *
   */
  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        end: "center center",
        scrub: true,
        start: "top center",
        trigger: comingSoonRef.current,
      },
    });

    tl.add(
      gsap.to(contentRef.current, {
        duration: 0.3,
        ease: "power1.inOut",
        opacity: 1,
        transform: "translateY(0px)",
      }),
      0
    );

    tl.add(
      gsap.to(photoRef.current, {
        delay: 0.25,
        duration: 0.4,
        ease: "power1.inOut",
        opacity: 1,
        transform: "translateY(0px)",
      }),
      0
    );
  });

  /**
   *
   */
  const renderAccordion = () => {
    const { comingSoonShowroomOpen } = club;

    if (!comingSoonShowroomOpen) {
      return <div />;
    }

    return comingSoonShowroomOpen.map((row, index) => {
      const { title, description } = row.fields;

      return (
        <Accordion
          accordionID={index.toString()}
          className={styles.comingSoonAccordion}
          key={title}
          label={title}
          selectAccordionFunc={setSelectedAccordion}
          selectedAccordion={selectedAccordion}
        >
          <span>{description}</span>
        </Accordion>
      );
    });
  };

  /**
   *
   */
  const renderImage = () => {
    const {
      comingSoonShowroomOpen,
      comingSoonShowroomOpenDefaultImage,
      comingSoonShowroomOpenDefaultImageMobile,
    } = club;

    return (
      <div className={styles.imgContainer}>
        {comingSoonShowroomOpenDefaultImage?.fields &&
        comingSoonShowroomOpenDefaultImageMobile?.fields ? (
          <ResponsiveImage
            altText="Coming soon"
            defaultImage={comingSoonShowroomOpenDefaultImageMobile}
            mdImage={comingSoonShowroomOpenDefaultImage}
          />
        ) : (
          <TransitionGroup component={null}>
            {comingSoonShowroomOpen?.map((slide, index) => {
              const key = `${index}-image`;

              if (selectedAccordion != index) {
                return null;
              }

              return (
                <CSSTransition
                  classNames={{
                    enter: "item-enter",
                    enterActive: "item-enter-active",
                    enterDone: "item-enter-active",
                    exit: "item-exit",
                    exitActive: "item-exit-active",
                    exitDone: "item-exit-active",
                  }}
                  key={key}
                  timeout={500}
                >
                  <ResponsiveImage
                    altText="Coming soon"
                    defaultImage={slide.fields.imageMobile}
                    mdImage={slide.fields.image}
                  />
                </CSSTransition>
              );
            })}
          </TransitionGroup>
        )}
      </div>
    );
  };

  return (
    <div className={styles.comingSoon} ref={comingSoonRef}>
      <div className={styles.content} ref={contentRef}>
        <h3 className={styles.headline}>120+ Classes Every Week</h3>

        <div className={styles.sellingPoints}>{renderAccordion()}</div>
      </div>

      <div className={styles.photo} ref={photoRef}>
        {renderImage()}
      </div>
    </div>
  );
};

ComingSoonPresaleClub.propTypes = {
  club: PropTypes.object.isRequired,
};

export default ComingSoonPresaleClub;
