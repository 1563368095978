import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";

import EditorialTitle from "@/components/EditorialTitle";
import Schedule from "@/components/club-detail/classes/ui-components/schedule";
import ThreeColumnExpander from "@/components/club-detail/classes/ui-components/three-column-expander";
import ModalWrap from "@/components/modal-wrap";
import { ACDL_GLOBAL_TRACKING } from "@/components/seo-and-analytics/adobe-data-layer/acdl-data-layer/global-tracking";
import { CLASS_SCHEDULE_DOWNLOAD_PATH } from "@/constants";
import useAcdl from "@/hooks/useAcdl";
import ImageSizes from "@/utils/helpers/images";

import styles from "./OpenClub.module.scss";

const OpenClub = ({
  classes: classesData,
  club,
  clubType,
  facilityId,
  handleClassScheduleAcdlEvent,
}) => {
  const { acdlPush } = useAcdl();

  const classHeaderRef = useRef();
  const classListRef = useRef();
  const contentRef = useRef();

  const router = useRouter();

  useGSAP(() => {
    gsap.registerPlugin(ScrollTrigger);

    const tl = gsap.timeline({
      scrollTrigger: {
        end: "center center",
        scrub: true,
        start: "top center",
        trigger: contentRef.current,
      },
    });

    tl.add(
      gsap.to(classHeaderRef.current, {
        duration: 0.3,
        ease: "power1.inOut",
        opacity: 1,
        transform: "translateY(0px)",
      }),
      0
    );

    tl.add(
      gsap.to(classListRef.current, {
        delay: 0.25,
        duration: 0.4,
        ease: "power1.inOut",
        opacity: 1,
        transform: "translateY(0px)",
      }),
      0
    );
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(
    classesData?.classCategory?.[0]?.classInstance?.classInstanceId || null
  );

  const downloadClassScheduleUrl = facilityId
    ? `${CLASS_SCHEDULE_DOWNLOAD_PATH}/${facilityId}/pdf`
    : "";

  /**
   *
   */
  const selectClass = (classID) => {
    if (selectedClass === classID) {
      setSelectedClass(null);
    } else {
      setSelectedClass(classID);
    }
  };

  /**
   *
   */
  const openClassSchedule = () => {
    handleClassScheduleAcdlEvent?.();
    setModalOpen(true);
  };

  /**
   *
   */
  const renderHeadlineSection = () => {
    if (!classesData) {
      return <div />;
    }

    return (
      <>
        <div className={styles.upcomingClassesTop}>
          <h3 className={styles.classesHeadline}>Classes this week</h3>

          {club.subcopy && (
            <p className={styles.classesDescription}>
              {club.subcopy.fields.subcopy}
            </p>
          )}
        </div>

        <div className={styles.classesCtas}>
          <button
            className={styles.viewClassSchedule}
            name="ClubDetail:Classes:UIComponents:OpenClub - View Class Schedule"
            onClick={openClassSchedule}
            type="button"
          >
            View Class Schedule
          </button>

          {facilityId && downloadClassScheduleUrl?.length > 0 && (
            <a
              className={styles.downloadClassSchedule}
              href={downloadClassScheduleUrl}
              onClick={() =>
                acdlPush(
                  ACDL_GLOBAL_TRACKING.classScheduleDownloadUrl(router.asPath)
                )
              }
              rel="noreferrer"
              target="_blank"
            >
              Download Class Schedule
            </a>
          )}
        </div>
      </>
    );
  };

  /**
   *
   */
  const renderClasses = () => {
    if (!classesData) {
      return <div />;
    }

    return classesData.classCategory.map((row) => {
      const { workoutCategoryName, classInstance } = row;

      return (
        <ThreeColumnExpander
          category={workoutCategoryName}
          description={classInstance.classDescription}
          featuredTag={classInstance.isNew ? "New" : ""}
          id={classInstance.classInstanceId}
          key={classInstance.classInstanceId}
          selectClass={selectClass}
          selectedClass={selectedClass}
          title={classInstance.className}
        />
      );
    });
  };

  /*
   * On card hover the parent background fades to asscoiated image
   */
  const renderBackgroundImage = () => {
    if (!classesData) {
      return null;
    }

    return (
      <div className={styles.backgroundImageContainer}>
        {classesData.classCategory.map((slide) => {
          const key = `${slide.classInstance.classInstanceId}-image`;
          const desktopImage =
            slide?.classInstance?.image ||
            slide?.categoryImage ||
            slide?.classInstance?.mobileImage ||
            slide?.categoryMobileImage;
          const mobileImage =
            slide?.classInstance?.mobileImage ||
            slide?.categoryMobileImage ||
            desktopImage;

          return (
            <React.Fragment key={key}>
              {desktopImage && (
                <div
                  aria-hidden={
                    selectedClass !== slide.classInstance.classInstanceId
                  }
                  className={`${styles.backgroundImage} ${styles.backgroundImageDesktop}`}
                  style={{
                    backgroundImage: `url(${
                      (desktopImage.indexOf("//") === 0 ? "https:" : "") +
                      desktopImage
                    }?${ImageSizes.fullScreenBackgroundDesktop()})`,
                  }}
                />
              )}
              {mobileImage && (
                <div
                  aria-hidden={
                    selectedClass !== slide.classInstance.classInstanceId
                  }
                  className={`${styles.backgroundImage} ${styles.backgroundImageMobile}`}
                  style={{
                    backgroundImage: `url(${
                      (mobileImage.indexOf("//") === 0 ? "https:" : "") +
                      mobileImage
                    }?${ImageSizes.fullScreenBackgroundMobile()})`,
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  return (
    <section className={styles.upcomingClasses}>
      {renderBackgroundImage()}

      <div className={styles.content} ref={contentRef}>
        <div className={styles.classHeader} ref={classHeaderRef}>
          {club?.editorialTitle ? (
            <EditorialTitle clubType={clubType} data={club.editorialTitle} />
          ) : (
            renderHeadlineSection()
          )}
        </div>

        <div className={styles.classList} ref={classListRef}>
          {renderClasses()}
        </div>
      </div>

      {facilityId && (
        <ModalWrap
          marginTop={15}
          onRequestClose={() => {
            setModalOpen(false);
          }}
          open={modalOpen}
        >
          <Schedule
            facilityId={parseInt(facilityId)}
            onRequestClose={() => {
              setModalOpen(false);
            }}
            title={club?.title}
          />
        </ModalWrap>
      )}
    </section>
  );
};

OpenClub.propTypes = {
  classes: PropTypes.object,
  club: PropTypes.object,
  clubType: PropTypes.string,
  facilityId: PropTypes.string.isRequired,
  handleClassScheduleAcdlEvent: PropTypes.func,
};

export default OpenClub;
